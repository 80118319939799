import { Box, Tooltip } from '@mui/material';
import { formatDate } from 'utils';
import { GridToolbar } from '@mui/x-data-grid';

const subscription = (data) => {
  return (
    <>
      <Box>
        <div>balance : {data.balance}</div>
        <div>type : {data?.type.split(':')[0]}</div>
        <div>expire at : {formatDate(data.expiration_time)}</div>
      </Box>
    </>
  );
};

export const columns = [
  {
    field: '_id',
    headerName: '_id',
    width: 230,
    type: 'string',
  },
  {
    field: 'email',
    headerName: 'email',
    width: 320,
    type: 'string',
  },

  {
    field: 'created_at',
    headerName: 'created at',
    width: 150,
    editable: false,
  },
  {
    field: 'activated',
    headerName: 'activated',
    width: 100,
    type: 'boolean',
  },
  {
    field: 'admin',
    headerName: 'admin',
    width: 160,
    editable: false,
    type: 'boolean',
  },
  {
    field: 'balance',
    headerName: 'balance',
    width: 100,
    editable: false,
  },
  {
    field: 'transactions',
    headerName: 'transactions',
    width: 100,
    editable: false,
  },
  {
    field: 'subscription',
    headerName: 'subscription',
    width: 100,
    editable: false,
    renderCell: (params) => (
      <Tooltip
        title={params.value.active ? subscription(params.value) : ''}
        arrow
      >
        <span className="cell-value">{params.value.active ? 'Yes' : 'No'}</span>
      </Tooltip>
    ),
    sortComparator: (v1, v2) => {
      if (v1.active < v2.active) return -1;
      if (v1.active > v2.active) return 1;
      return 0;
    },
  },
  {
    field: 'used_balance',
    headerName: 'used_balance',
    width: 100,
    editable: false,
  },
];
